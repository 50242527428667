import { call, put, takeLatest } from "redux-saga/effects";

import axios from "axios";

export default function* mySaga() {
  console.log("saga");
  yield takeLatest("API_BUCKETS_REQUEST", buckets);
}

function* buckets(data) {
  const { payload, meta } = data;
  try {
    const response = yield call(axios, payload);
    yield put({
      type: "API_BUCKETS_SUCCESS",
      payload: response,
      meta,
    });
  } catch (e) {
    yield put({
      type: "API_BUCKETS_FAILURE",
      payload: e,
      error: true,
      meta,
    });
  }
}

import axios from "axios";

export const FORGOTPASSWORD_ERROR = "FORGOTPASSWORD_ERROR";
export const FORGOTPASSWORD_SUCCESS = "FORGOTPASSWORD_SUCCESS";

export function submitForgotPassword({ email }) {
  return (dispatch) =>
    axios
      .post("/apiadvertiser/forgotpassword/request", {
        email: email,
      })
      .then((response) => {
        return dispatch({
          type: FORGOTPASSWORD_SUCCESS,
          payload: response.data.success,
        });
      })
      .catch((error) => {
        return dispatch({
          type: FORGOTPASSWORD_ERROR,
          payload:
            error.response.data.error !== undefined
              ? error.response.data.error
              : "Unknown error",
        });
      });
}

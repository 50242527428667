/**
 * Authorization Roles
 */
export const authRoles = {
  admin: ["admin"],
  staff: ["admin", "staff"],
  user: ["admin", "staff", "user"],
  onlyGuest: [],

  usersViewer: ["users-viewer"],
  breakdownstatsViewer: ["breakdownstats-viewer"],
  breakdownStatsEventsViewer: ["breakdownstats-events-viewer"],
  breakdownStatsPurchasesViewer: ["breakdownstats-purchases-viewer"],
  breakdownStatsRetentionViewer: ["breakdownstats-retention-viewer"],
  breakdownStatsOSVersionsViewer: ["breakdownstats-osversions-viewer"],
  breakdownStatsByDateViewer: ["breakdownstats-bydate-viewer"],
};

export const allPermissions = [
  "admin",
  "staff",
  "users-viewer",
  "breakdownstats-viewer",
  "breakdownstats-events-viewer",
  "breakdownstats-purchases-viewer",
  "breakdownstats-retention-viewer",
  "breakdownstats-osversions-viewer",
  "breakdownstats-bydate-viewer",
];

export const userRoles = [
  {
    name: "Advertiser",
    permissions: ["staff", ...authRoles.usersViewer],
  },
];

export default authRoles;

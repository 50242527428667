import { LoginConfig } from "app/containers/Login/LoginConfig";
import { ForgotPasswordConfig } from "app/containers/ForgotPassword/ForgotPasswordConfig";
import { ResetPasswordConfig } from "app/containers/ResetPassword/ResetPasswordConfig";
import { LogoutConfig } from "app/containers/Logout/LogoutConfig";
import React from "react";
import { TapchampsUtils } from "@tapchamps";
import { breakdownstatsConfigs } from "app/containers/BreakdownStats/BreakdownStatsConfig";
import { usersConfigs } from "app/containers/Users/UsersConfig";

const routeConfigsGuest = [
  LoginConfig,
  ForgotPasswordConfig,
  ResetPasswordConfig,
];

const routeConfigs = [...usersConfigs, ...breakdownstatsConfigs, LogoutConfig];

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  ...TapchampsUtils.generateRoutesFromConfigs(routeConfigsGuest, null),
  ...TapchampsUtils.generateRoutesFromConfigs(routeConfigs, ["admin", "staff"]),
  { path: "/", exact: true, name: "Home", auth: ["admin", "staff"] },
];

export default routes;

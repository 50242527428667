import React, { Component } from "react";
import { Spinner, Table } from "reactstrap";

import PropTypes from "prop-types";

const propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.object,
};

class WidgetGameAgeBuckets extends Component {
  render() {
    const { data, loading, selectedEventnames, groupBy } = this.props;
    let cols = 12;
    if (!this.props.isRetention) {
      cols -= 8;
    }
    if (!this.props.isPurchases) {
      cols -= 2;
    }
    return (
      <>
        {loading === true ? (
          <Spinner color="primary" size="md" />
        ) : (
          <Table responsive size="sm">
            <thead>
              {selectedEventnames.length > 0 ? (
                <tr>
                  <th colSpan={cols}>STATS</th>
                  <th colSpan={selectedEventnames.length}>EVENT NAMES</th>
                </tr>
              ) : null}
              <tr>
                <th>Gender</th>
                <th>
                  {groupBy === 0
                    ? "Age Bucket"
                    : groupBy === 1
                    ? "OS Version"
                    : ""}
                </th>
                <th>Installs</th>
                <th>Average Total Play Time (m)</th>
                <th>Number of Times Played</th>
                <th>Average Number of Sessions</th>
                <th>Average Game Level</th>
                {this.props.isRetention && (
                  <>
                    <th>Retention D0 (%)</th>
                    <th>Retention D1 (%)</th>
                    <th>Retention D2 (%)</th>
                    <th>Retention D3 (%)</th>
                    <th>Retention D4 (%)</th>
                    <th>Retention D5 (%)</th>
                    <th>Retention D6 (%)</th>
                    <th>Retention D7 (%)</th>
                  </>
                )}
                {this.props.isPurchases && (
                  <>
                    <th>Purchases</th>
                    <th>% of People who purchased</th>
                  </>
                )}
                {selectedEventnames.map((name) => (
                  <th>{name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.keys(data).map((gender) => {
                const d = data[gender];
                const count = d.length;
                return d.map((row, i) => {
                  const {
                    bracket,
                    nop,
                    atpt,
                    notp,
                    anos,
                    agl,
                    r_retention_ratio_day_0,
                    r_retention_ratio_day_1,
                    r_retention_ratio_day_2,
                    r_retention_ratio_day_3,
                    r_retention_ratio_day_4,
                    r_retention_ratio_day_5,
                    r_retention_ratio_day_6,
                    r_retention_ratio_day_7,
                    p,
                    pop,
                  } = row;
                  return (
                    <tr key={`${gender}-${i}`}>
                      {i === 0 ? (
                        <td rowSpan={count}>
                          <b>{gender}</b>
                        </td>
                      ) : null}
                      <td>{bracket}</td>
                      <td>{nop}</td>
                      <td>{atpt}</td>
                      <td>{notp}</td>
                      <td>{anos}</td>
                      <td>{agl}</td>
                      {this.props.isRetention && (
                        <>
                          <td>{r_retention_ratio_day_0}</td>
                          <td>{r_retention_ratio_day_1}</td>
                          <td>{r_retention_ratio_day_2}</td>
                          <td>{r_retention_ratio_day_3}</td>
                          <td>{r_retention_ratio_day_4}</td>
                          <td>{r_retention_ratio_day_5}</td>
                          <td>{r_retention_ratio_day_6}</td>
                          <td>{r_retention_ratio_day_7}</td>
                        </>
                      )}
                      {this.props.isPurchases && (
                        <>
                          <td>{p}</td>
                          <td>{pop}</td>
                        </>
                      )}
                      {selectedEventnames.map((e) => (
                        <td>{row[`e_${e}`]}</td>
                      ))}
                    </tr>
                  );
                });
              })}
            </tbody>
          </Table>
        )}
      </>
    );
  }
}

WidgetGameAgeBuckets.propTypes = propTypes;

export default WidgetGameAgeBuckets;

import * as Yup from "yup";
import * as authActions from "app/auth/store/actions";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Formik } from "formik";

const validationSchema = function (values) {
  return Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required!"),
  });
};

let submitActions = null;
function ForgotPassword(props) {
  const dispatch = useDispatch();
  const forgotpassword = useSelector(({ auth }) => auth.forgotpassword);

  useEffect(() => {
    if (forgotpassword.error) {
      const { setSubmitting, setErrors } = submitActions;
      setSubmitting(false);
      setErrors({ email: forgotpassword.error });
    }
  }, [forgotpassword.error]);

  const validate = (getValidationSchema) => {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  };

  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce((errors, error) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR],
      };
    }, {});
  };

  const initialValues = {
    email: "",
  };

  const onSubmit = (values, actions) => {
    submitActions = actions;
    dispatch(authActions.submitForgotPassword(values));
  };

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="4">
            <CardGroup>
              <Card className="p-4">
                <CardBody>
                  {forgotpassword.success === false ? (
                    <Formik
                      initialValues={initialValues}
                      validate={validate(validationSchema)}
                      onSubmit={onSubmit}
                      render={({
                        values,
                        errors,
                        touched,
                        status,
                        dirty,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        handleReset,
                        setTouched,
                      }) => (
                        <Form onSubmit={handleSubmit} noValidate>
                          <h1>Forgot Password</h1>
                          <p className="text-muted">
                            A password reset link will be sent to your email
                          </p>
                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="icon-user"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="text"
                              id="email"
                              placeholder="Email"
                              valid={!errors.email}
                              invalid={touched.email && !!errors.email}
                              required
                              autoFocus={false}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                            />
                            <FormFeedback>{errors.email}</FormFeedback>
                          </InputGroup>
                          <Row>
                            <Col xs="12">
                              <Button
                                type="submit"
                                color="primary"
                                className="px-4"
                                disabled={isSubmitting}
                              >
                                Reset password
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      )}
                    />
                  ) : (
                    <p>{forgotpassword.success}</p>
                  )}
                  <Row>
                    <Col className="text-right mt-3">
                      <Link to="/">Login</Link>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ForgotPassword;

import { authRoles } from "app/auth";

export default {
  items: [
    {
      name: "Users",
      url: "/users",
      icon: "icon-people",
      auth: [...authRoles.admin, ...authRoles.usersViewer],
    },
    {
      name: "Breakdown Stats",
      url: "/breakdownstats",
      icon: "icon-chart",
      auth: [...authRoles.admin, ...authRoles.breakdownstatsViewer],
    },
  ],
};

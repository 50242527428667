import BreakdownStats from "./BreakdownStats";
import { authRoles } from "app/auth";

const BreakdownStatsConfig = {
  auth: [...authRoles.admin, ...authRoles.breakdownstatsViewer],
  routes: [
    {
      path: "/breakdownstats",
      component: BreakdownStats,
      name: "Breakdown Stats",
    },
  ],
};

export const breakdownstatsConfigs = [BreakdownStatsConfig];

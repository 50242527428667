import ForgotPassword from "./ForgotPassword";
import { authRoles } from "app/auth";

export const ForgotPasswordConfig = {
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: "/forgotpassword",
      component: ForgotPassword,
      name: "Forgot Password Page",
    },
  ],
};

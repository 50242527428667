import * as Yup from "yup";
import * as authActions from "app/auth/store/actions";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Formik } from "formik";

const validationSchema = function (values) {
  return Yup.object().shape({
    password: Yup.string()
      .min(6, `Password has to be at least ${6} characters!`)
      .matches(
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/,
        "Password must contain: numbers, uppercase and lowercase letters\n"
      )
      .required("Password is required!"),
    passwordConfirm: Yup.string().required("Password confirm is required!"),
  });
};

let submitActions = null;
function ResetPassword(props) {
  if (
    props.match === undefined ||
    props.match.params === undefined ||
    props.match.params.token === undefined ||
    props.match.params.user_id === undefined ||
    props.match.params.token === "" ||
    props.match.params.user_id === ""
  ) {
    props.history.push({
      pathname: "/login",
    });
  }

  const dispatch = useDispatch();
  const resetpassword = useSelector(({ auth }) => auth.resetpassword);

  useEffect(() => {
    if (resetpassword.error) {
      const { setSubmitting, setErrors } = submitActions;
      setSubmitting(false);
      setErrors({ passwordConfirm: resetpassword.error });
    }
  }, [resetpassword.error]);

  const validate = (getValidationSchema) => {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, { abortEarly: false });
        if (values.password !== values.passwordConfirm) {
          return { passwordConfirm: "Passwords not match" };
        }
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  };

  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce((errors, error) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR],
      };
    }, {});
  };

  const initialValues = {
    password: "",
    passwordConfirm: "",
  };

  const onSubmit = (values, actions) => {
    submitActions = actions;
    dispatch(
      authActions.submitResetPassword({
        password: values.password,
        token: props.match.params.token,
        user_id: props.match.params.user_id,
      })
    );
  };

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="4">
            <CardGroup>
              <Card className="p-4">
                <CardBody>
                  {resetpassword.success === false ? (
                    <Formik
                      initialValues={initialValues}
                      validate={validate(validationSchema)}
                      onSubmit={onSubmit}
                      render={({
                        values,
                        errors,
                        touched,
                        status,
                        dirty,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        handleReset,
                        setTouched,
                      }) => (
                        <Form onSubmit={handleSubmit} noValidate>
                          <h1>Reset Password</h1>
                          <p className="text-muted">Set new password</p>
                          <InputGroup className="mb-4">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="icon-lock"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              id="password"
                              type="password"
                              placeholder="Password"
                              valid={!errors.password}
                              invalid={touched.password && !!errors.password}
                              required
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                            />
                            <FormFeedback>{errors.password}</FormFeedback>
                          </InputGroup>
                          <InputGroup className="mb-4">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="icon-lock"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              id="passwordConfirm"
                              type="password"
                              placeholder="Password Confirm"
                              valid={!errors.passwordConfirm}
                              invalid={
                                touched.passwordConfirm &&
                                !!errors.passwordConfirm
                              }
                              required
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.passwordConfirm}
                            />
                            <FormFeedback>
                              {errors.passwordConfirm}
                            </FormFeedback>
                          </InputGroup>
                          <Row>
                            <Col xs="12">
                              <Button
                                type="submit"
                                color="primary"
                                className="px-4"
                                disabled={isSubmitting}
                              >
                                Reset password
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      )}
                    />
                  ) : (
                    <p>{resetpassword.success}</p>
                  )}
                  <Row>
                    <Col className="text-right mt-3">
                      <Link to="/">Login</Link>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ResetPassword;

import axios from "axios";

export const RESETPASSWORD_SUCCESS = "RESETPASSWORD_SUCCESS";
export const RESETPASSWORD_ERROR = "RESETPASSWORD_ERROR";

export function submitResetPassword({ user_id, token, password }) {
  return (dispatch) =>
    axios
      .post("/apiadvertiser/forgotpassword/set", {
        user_id,
        token,
        password,
      })
      .then((response) => {
        return dispatch({
          type: RESETPASSWORD_SUCCESS,
          payload: response.data.success,
        });
      })
      .catch((error) => {
        return dispatch({
          type: RESETPASSWORD_ERROR,
          payload:
            error.response.data.error !== undefined
              ? error.response.data.error
              : "Unknown error",
        });
      });
}

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./react_dates_overrides.css";

import * as Actions from "./store/actions";

import {
  ButtonGroup,
  ButtonToggle,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import React, { Component } from "react";

import { DateRangePicker } from "react-dates";
import { Multiselect } from "multiselect-react-dropdown";
import { TapchampsUtils } from "@tapchamps";
import WidgetAgeBuckets from "app/views/Widgets/WidgetAgeBuckets";
import WidgetByDate from "app/views/Widgets/WidgetByDate";
import WidgetGameAgeBuckets from "app/views/Widgets/WidgetGameAgeBuckets";
import WidgetGameByDate from "app/views/Widgets/WidgetGameByDate";
import _ from "lodash";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "app/store/withReducer";

const multiselectStyle = {
  multiselectContainer: {},
  searchBox: {
    //border: "none",
    fontSize: 12,
    height: 35,
    padding: 3,
  },
  chips: {
    fontSize: 10,
  },
  option: {
    fontSize: 12,
  },
};

const multiselectStyleGame = {
  multiselectContainer: {
    minWidth: 300,
  },
  searchBox: {
    //border: "none",
    fontSize: 12,
    height: 35,
    padding: 3,
  },
  chips: {
    fontSize: 10,
  },
  option: {
    fontSize: 12,
  },
};

class BreakdownStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      game: "",
      eventnames: [],
      groupBy: 0,
      focusedInput: null,
      startDate: null,
      endDate: null,
    };

    this.onGroupByChange = this.onGroupByChange.bind(this);
  }
  componentDidMount() {
    const {
      agebuckets: { startDate, endDate },
    } = this.props;
    this.setState({ startDate, endDate }, () => {
      this.props.dispatch(Actions.getBuckets());
      this.props.dispatch(Actions.getGamesForSelect());
      this.props.dispatch(Actions.getGamesEventsForSelect());
    });
  }
  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  onGameSelect(selectedList, selectedItem) {
    this.setState({ game: selectedItem.id, eventnames: [] });

    this.props.dispatch(
      Actions.getBuckets(selectedItem.id, [], this.state.groupBy)
    );
    this.props.dispatch(Actions.getGamesEventsForSelect(selectedItem.id));
  }
  onGameRemove(selectedList, removedItem) {
    this.setState({ game: "", eventnames: [] });

    this.props.dispatch(Actions.getBuckets("", [], this.state.groupBy));
    this.props.dispatch(Actions.getGamesEventsForSelect(""));
  }
  onEventNameSelect(selectedList, selectedItem) {
    this.setState({
      eventnames: selectedList,
    });
    this.props.dispatch(
      Actions.getBuckets(this.state.game, selectedList, this.state.groupBy)
    );
  }
  onEventNameRemove(selectedList, removedItem) {
    this.setState({
      eventnames: selectedList,
    });
    this.props.dispatch(
      Actions.getBuckets(this.state.game, selectedList, this.state.groupBy)
    );
  }

  onGroupByChange(groupBy) {
    this.setState({ groupBy });
    this.props.dispatch(
      Actions.getBuckets(this.state.game, this.state.eventnames, groupBy)
    );
  }

  render() {
    const { loading } = this.props;
    if (loading) {
      return loading();
    }

    const canViewBreakdown = TapchampsUtils.hasPermission(
      ["admin", "breakdownstats-viewer"],
      this.props.user.role
    );

    const canViewEvents = TapchampsUtils.hasPermission(
      ["admin", "breakdownstats-events-viewer"],
      this.props.user.role
    );

    const canViewPurchases = TapchampsUtils.hasPermission(
      ["admin", "breakdownstats-purchases-viewer"],
      this.props.user.role
    );

    const canViewRetention = TapchampsUtils.hasPermission(
      ["admin", "breakdownstats-retention-viewer"],
      this.props.user.role
    );

    const canViewOsVersions = TapchampsUtils.hasPermission(
      ["admin", "breakdownstats-osversions-viewer"],
      this.props.user.role
    );

    const canViewByDate = TapchampsUtils.hasPermission(
      ["admin", "breakdownstats-bydate-viewer"],
      this.props.user.role
    );

    const {
      agebuckets: {
        data: bucket_data,
        loading: bucket_loading,
        eventsforselect,
        gamesforselect,
      },
    } = this.props;

    const { game } = this.state;

    let tableToRender;
    if (this.state.groupBy === 2) {
      tableToRender =
        game === undefined || game === "" ? (
          <WidgetByDate
            loading={bucket_loading}
            data={bucket_data}
            selectedEventnames={this.state.eventnames}
            isPurchases={canViewPurchases}
            isRetention={canViewRetention}
            groupBy={this.state.groupBy}
          />
        ) : (
          <WidgetGameByDate
            loading={bucket_loading}
            data={bucket_data}
            selectedEventnames={this.state.eventnames}
            isPurchases={canViewPurchases}
            isRetention={canViewRetention}
            groupBy={this.state.groupBy}
          />
        );
    } else {
      tableToRender =
        game === undefined || game === "" ? (
          <WidgetAgeBuckets
            loading={bucket_loading}
            data={bucket_data}
            selectedEventnames={this.state.eventnames}
            isPurchases={canViewPurchases}
            isRetention={canViewRetention}
            groupBy={this.state.groupBy}
          />
        ) : (
          <WidgetGameAgeBuckets
            loading={bucket_loading}
            data={bucket_data}
            selectedEventnames={this.state.eventnames}
            isPurchases={canViewPurchases}
            isRetention={canViewRetention}
            groupBy={this.state.groupBy}
          />
        );
    }
    return (
      canViewBreakdown && (
        <div className="animated fadeIn">
          <Row>
            <Col>
              <ButtonGroup className="float-left mb-2">
                <ButtonToggle
                  active={this.state.groupBy === 0}
                  onClick={(e) => {
                    this.onGroupByChange(0);
                  }}
                >
                  Age
                </ButtonToggle>
                {canViewOsVersions && (
                  <ButtonToggle
                    active={this.state.groupBy === 1}
                    onClick={(e) => {
                      this.onGroupByChange(1);
                    }}
                  >
                    OS Version
                  </ButtonToggle>
                )}
                {canViewByDate && (
                  <ButtonToggle
                    active={this.state.groupBy === 2}
                    onClick={(e) => {
                      this.onGroupByChange(2);
                    }}
                  >
                    Date
                  </ButtonToggle>
                )}
              </ButtonGroup>
              <FormGroup className="float-right mb-2">
                <Multiselect
                  id="search1"
                  selectionLimit={1}
                  loading={loading}
                  style={multiselectStyleGame}
                  options={gamesforselect}
                  placeholder="Game"
                  onSelect={this.onGameSelect.bind(this)}
                  onRemove={this.onGameRemove.bind(this)}
                  displayValue="name"
                />
              </FormGroup>
              {canViewEvents && (
                <FormGroup className="float-right mb-2 mr-1">
                  <Multiselect
                    id="search2"
                    loading={loading}
                    style={multiselectStyle}
                    options={_.uniq(eventsforselect.map((obj) => obj.name))}
                    isObject={false}
                    placeholder="Event Name"
                    onSelect={this.onEventNameSelect.bind(this)}
                    onRemove={this.onEventNameRemove.bind(this)}
                    displayValue="name"
                    selectedValues={this.state.eventnames}
                  />
                </FormGroup>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  {this.state.groupBy === 0
                    ? "Age Breakdown"
                    : this.state.groupBy === 1
                    ? "OS Version Breakdown"
                    : this.state.groupBy === 2
                    ? "Date Breakdown"
                    : ""}
                  <FormGroup className="float-right mb-0">
                    <DateRangePicker
                      isOutsideRange={() => false}
                      startDate={this.state.startDate}
                      startDateId="startDate"
                      endDate={this.state.endDate}
                      endDateId="endDate"
                      small
                      onDatesChange={({ startDate, endDate }) =>
                        this.setState({ startDate, endDate })
                      }
                      focusedInput={this.state.focusedInput}
                      onFocusChange={(focusedInput) => {
                        this.setState({ focusedInput });
                        if (focusedInput === null) {
                          setTimeout(() => {
                            this.props.dispatch(
                              Actions.setDateRange(
                                this.state.game,
                                this.state.eventnames,
                                this.state.groupBy,
                                this.state.startDate,
                                this.state.endDate
                              )
                            );
                          }, 500);
                        }
                      }}
                      orientation={this.state.orientation}
                      openDirection={this.state.openDirection}
                    />
                  </FormGroup>
                </CardHeader>
                <CardBody>{tableToRender}</CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    agebuckets: state.breakdownstats.agebuckets,
    user: state.auth.user,
  };
};

export default withReducer(
  "breakdownstats",
  reducer
)(connect(mapStateToProps)(BreakdownStats));

import ResetPassword from "./ResetPassword";
import { authRoles } from "app/auth";

export const ResetPasswordConfig = {
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: "/resetpassword",
      component: ResetPassword,
      name: "Reset Password Page",
    },
  ],
};
